// src/App.js
import React from 'react';
import './App.css';
import StoryGenerator from './components/StoryGenerator';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>智绘乐学v0.1</h1>
      </header>
      <StoryGenerator />
    </div>
  );
}

export default App;

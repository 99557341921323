// src/api/openai.js
import { OpenAI } from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});
//生成文本
export const generateStory = async (prompt) => {
  try {
    const response = await openai.chat.completions.create({
        messages: [{ role: "system", content: prompt}],
        model: "gpt-3.5-turbo",
    });
    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error generating story:", error);
    return "Sorry, there was an error generating the story.";
  }
};
//生成图像
export const generateImage = async (prompt) => {
    try {
      const response = await openai.images.generate({
        model: "dall-e-3",
        prompt: prompt,
      });
      // 假设响应结构与你之前提供的一致
      const imageUrls = response.data.map(img => img.url);
      return imageUrls; // 返回图像URLs数组
    } catch (error) {
      console.error("Error generating image:", error);
      return []; // 出错时返回空数组
    }
  };

import React, { useState } from 'react';
import { generateStory, generateImage } from '../api/openai'; // 确保路径正确
import ProgressBar from './ProgressBar'; // 引入进度条组件

function StoryGenerator() {
    const [name, setName] = useState('');
    const [theme, setTheme] = useState('');
    const [story, setStory] = useState('');
    const [images, setImages] = useState([]);
    const [progress, setProgress] = useState(0); // 添加用于控制进度条的状态

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProgress(10);
        const storyPrompt = `生成一个幼儿通话故事，主题为： ${theme} ，主角名字是 ${name} `;
        const imagePrompt = `Create an disney style image about ${theme} for a kid named ${name}.`;

        // Generate story
        setProgress(30);
        const generatedStory = await generateStory(storyPrompt);
        setStory(generatedStory || 'Failed to generate story.');
        setProgress(60); 
        // Generate images
        const generatedImages = await generateImage(imagePrompt);
        setImages(generatedImages);
        setProgress(100);
    };

    return (
        <div>
            <h2>现在就开始书写宝宝的故事吧！</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>孩子名字: <input type="text" value={name} onChange={(e) => setName(e.target.value)} /></label>
                </div>
                <div>
                    <label>故事大纲: <input type="text" value={theme} onChange={(e) => setTheme(e.target.value)} /></label>
                </div>
                <button type="一键生成">一键生成</button>
            </form>
            <ProgressBar progress={progress} /> {/* 显示进度条 */}
            {story && <div><h3>故事内容:</h3><p>{story}</p></div>}
            {images.length > 0 && (
                <div>
                    <h3>插图:</h3>
                    {images.map((img, index) => <img key={index} src={img} alt="Generated" />)}
                </div>
            )}
        </div>
    );
}

export default StoryGenerator;

// ProgressBar.js
import React from 'react';

function ProgressBar({ progress }) {
  const containerStyles = {
    height: '20px',
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: '50px',
    margin: '20px 0',
  };

  const fillerStyles = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: '#007bff',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
  };

  const labelStyles = {
    padding: '5px',
    color: 'white',
    fontWeight: 'bold',
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${progress}%`}</span>
      </div>
    </div>
  );
}

export default ProgressBar;
